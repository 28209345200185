import api from '..'

export const uploadImages = async (images: FileList | null | undefined) => {
  if (!images) return Promise.resolve([])

  const filesPromise = Array.from(images).map(async (image) => {
    const formData = new FormData()
    formData.append('images', image)

    const response = await api.post('auth/customer/upload/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return response.data[0]
  })

  return Promise.all(filesPromise)
}
