import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSearchParams } from 'react-router-dom'
import Button from '../components/button'
import Table, { ColumnsType } from '../components/table'
import { useCustomerPatients } from '../hooks/useCustomerPatients'
import Pagination from '../components/pagination'
import { PatientForm } from '../api/patients/types'
import CreatePatientModal from '../components/modals/create_or_update_patient_modal'
import { createPatientSchema } from '../constants/schemas/createPatient'

interface DataType {
  key: string;
  name: string;
  description?: string | null;
}

export default function Patients () {
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || '1'
  const {
    customerPatients, pagination, createCustumerPatient, updateCustumerPatient, isLoading
  } = useCustomerPatients({ page })

  const [modalState, setModalState] = useState({ isModalVisible: false, isUpdate: false })
  const form = useForm<PatientForm>({
    resolver: yupResolver(createPatientSchema),
    defaultValues: {
      name: '',
      description: ''
    }
  })

  const [currentPatientId, setCurrentPatientId] = useState<string | null>(null)

  const dataSource = customerPatients.map((patient) => ({
    key: patient.id.toString(),
    name: patient.name,
    description: patient.description
  }))

  const onSubmit = (data: PatientForm) => {
    if (modalState.isUpdate) {
      updateCustumerPatient({ ...data, id: parseInt(currentPatientId || '', 10) })
    } else {
      createCustumerPatient({
        name: data.name,
        description: data.description
      })
    }
    setModalState({ isUpdate: false, isModalVisible: false })
    form.reset()
  }

  const onOpenModalUpdatePatient = (patient: DataType) => {
    setModalState((prevState) => ({ ...prevState, isModalVisible: true, isUpdate: true }))
    setCurrentPatientId(patient.key)
    form.setValue('name', patient.name)
    form.setValue('description', patient.description)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'key',
      key: 'id'
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a href="#1">{text}</a>
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Acción',
      key: 'action',
      dataIndex: 'action',
      render: (_, patient) => (
        <Button fill="text" onClick={() => onOpenModalUpdatePatient(patient)}>
          Editar
        </Button>
      )
    }
  ]

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">Pacientes</h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">
            Una lista de todos tus pacientes incluidos sus nombres, descripción, etc.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button label="Nuevo paciente" onClick={() => setModalState((prevState) => ({ ...prevState, isModalVisible: true }))} />
        </div>
      </div>
      <Table dataSource={dataSource} columns={columns} isFetching={isLoading} />
      <Pagination
        pages={pagination.pages || []}
        nextPage={pagination.nextPage}
        prevPage={pagination.prevPage}
      />
      <CreatePatientModal
        isModalVisible={modalState.isModalVisible}
        setIsModalVisible={
          (isModalVisible: boolean) => setModalState({ isUpdate: false, isModalVisible })
        }
        form={form}
        onSubmit={onSubmit}
        actionLabel={modalState.isUpdate ? 'Actualizar' : 'Crear'}
      />
    </div>
  )
}
