import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { StatComponentValue } from './types'

export interface StatCardProps {
  name: string
  stat: string
  icon: StatComponentValue['icon']
  change?: string | null
  changeType?: string | null
}

function classNames (...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function StatCard (item: StatCardProps) {
  const {
    change, changeType, name, stat
  } = item
  return (
    <div
      className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
    >
      <dt>
        <div className="absolute rounded-md bg-primary p-3">
          <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">{name}</p>
      </dt>
      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">{stat}</p>
        {
            change && changeType && (
            <p
              className={classNames(
                changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                'ml-2 flex items-baseline text-sm font-semibold'
              )}
            >
              {changeType === 'increase'
                ? (
                <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  )
                : (
                <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}

              <span className="sr-only">
                {' '}
                {changeType === 'increase' ? 'Increased' : 'Decreased'}
                {' '}
                by
                {' '}
              </span>
              {change}
            </p>
            )
        }
      </dd>
    </div>
  )
}

export default StatCard
