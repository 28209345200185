export type BadgeColorType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  label?: string;
  color?: BadgeColorType;
}

const backgroundAndTextColors = {
  primary: {
    backgroundColor: 'bg-primary',
    textColor: 'text-white'
  },
  secondary: {
    backgroundColor: 'bg-secondary',
    textColor: 'text-white'
  },
  success: {
    backgroundColor: 'bg-success',
    textColor: 'text-white'
  },
  danger: {
    backgroundColor: 'bg-danger',
    textColor: 'text-danger-light'
  },
  warning: {
    backgroundColor: 'bg-warning',
    textColor: 'text-warning-light'
  },
  info: {
    backgroundColor: 'bg-info',
    textColor: 'text-info-light'
  },
  light: {
    backgroundColor: 'bg-light',
    textColor: 'text-black'
  },
  dark: {
    backgroundColor: 'bg-dark',
    textColor: 'text-white'
  }
}

function Badge ({
  label,
  color = 'primary',
  ...rest
}: BadgeProps) {
  const { backgroundColor, textColor } = backgroundAndTextColors[color]
  return (
    <span
      {...rest}
      className={`inline-flex items-center rounded-full ${backgroundColor} px-2.5 py-0.5 text-xs font-medium ${textColor} ${rest.className} min-w-max`}
    >
      {label}
    </span>
  )
}

export default Badge
