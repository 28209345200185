import { UseFormReturn } from 'react-hook-form'
import { omitObjectKey } from '../../utils/omitObjectKey'
import Input from '../input'
import Modal from '../modal'
import { CustomerProifle } from '../../api/profile/types'

interface UpdateProfileModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  form: UseFormReturn<CustomerProifle>;
  onSubmit: (data: CustomerProifle) => void;
}

function UpdateProfileModal ({
  isModalVisible,
  setIsModalVisible,
  form,
  onSubmit
}: UpdateProfileModalProps) {
  return (
    <Modal
      visible={isModalVisible}
      title="Actualizar información"
      confirmButtonProps={{ form: 'customer-profile', type: 'submit', label: 'Actualizar' }}
      onConfirm={() => ({})}
      onCancel={() => {
        setIsModalVisible(false)
        form.reset()
      }}
      closeOnBlur={false}
    >
      <form id="customer-profile" onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <Input
          label="Nombre"
          type="text"
          {...omitObjectKey(form.register('name'), 'ref')}
          innerRef={form.register('name').ref}
          error={form.formState.errors.name?.message}
          autoComplete="false"
          readOnly
          disabled
        />
        <Input
          label="Email"
          type="email"
          id="email"
          {...omitObjectKey(form.register('email'), 'ref')}
          innerRef={form.register('email').ref}
          error={form.formState.errors.email?.message}
          autoComplete="false"
        />
        <Input
          label="Teléfono"
          type="tel"
          id="phone"
          {...omitObjectKey(form.register('phone'), 'ref')}
          innerRef={form.register('phone').ref}
          error={form.formState.errors.phone?.message}
        />
        <Input
          label="Contraseña"
          type="password"
          id="password"
          {...omitObjectKey(form.register('password'), 'ref')}
          innerRef={form.register('password').ref}
          error={form.formState.errors.password?.message}
          autoComplete="false"
        />
        <Input
          label="Confirmar contraseña"
          type="password"
          id="passwordConfirm"
          {...omitObjectKey(form.register('passwordConfirm'), 'ref')}
          innerRef={form.register('passwordConfirm').ref}
          error={form.formState.errors.passwordConfirm?.message}
          autoComplete="false"
        />
      </form>
    </Modal>
  )
}

export default UpdateProfileModal
