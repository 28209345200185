import { getUserNameInitials } from '../../utils/getUserNameInitials'

export default function UserNameIcon ({ username }: { username: string }) {
  const name = getUserNameInitials(username)
  return (
    <div className="h-8 w-8 rounded-full bg-gray-600 text-white flex justify-center items-center">
      <span className="font-bold text-sm h-auto">
        { name }
      </span>
    </div>
  )
}
