import { UseFormReturn } from 'react-hook-form'
import { omitObjectKey } from '../../utils/omitObjectKey'
import Input from '../input'
import Modal from '../modal'
import { PatientForm } from '../../api/patients/types'

interface CreatePatientModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  form: UseFormReturn<PatientForm>;
  onSubmit: (data: PatientForm) => void;
  actionLabel?: string;
}

function CreateOrUpdatePatientModal ({
  isModalVisible,
  form,
  actionLabel = 'Agregar',
  setIsModalVisible,
  onSubmit
}: CreatePatientModalProps) {
  return (
    <Modal
      visible={isModalVisible}
      title="Agregar nuevo paciente"
      confirmButtonProps={{
        form: 'patient-form',
        type: 'submit',
        label: actionLabel
      }}
      onConfirm={() => ({})}
      onCancel={() => {
        setIsModalVisible(false)
        form.reset()
      }}
      closeOnBlur={false}
    >
      <form
        id="patient-form"
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 mt-4"
      >
        <Input
          label="Nombre"
          type="text"
          {...omitObjectKey(form.register('name'), 'ref')}
          innerRef={form.register('name').ref}
          error={form.formState.errors.name?.message}
        />
        <Input
          label="Descripción"
          type="text"
          id="description"
          {...omitObjectKey(form.register('description'), 'ref')}
          innerRef={form.register('description').ref}
          error={form.formState.errors.description?.message}
        />
      </form>
    </Modal>
  )
}

export default CreateOrUpdatePatientModal
