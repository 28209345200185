import { useMutation, useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { WorkOrder, createCustomerWorkOrderFn, getCustomerWorkOrdersConfigurationFn, getCustomerWorkOrdersFn } from '../api/workOrders'
import useStore from '../store'
import { usePagination } from './usePagination'

export const useCustomerWorkOrders = ({ page = '1', onSuccessWorkOrderCreation }: { page: string, onSuccessWorkOrderCreation?: () => void }) => {
  const store = useStore()
  const addToastMessage = useStore((state) => state.addMessage)

  const { data, refetch, isFetching: isFetchingCustomerWorkOrders } = useQuery({
    queryKey: ['customerWorkOrder', page],
    queryFn: () => getCustomerWorkOrdersFn(page),
    onSuccess () {
      store.setRequestLoading(false)
    },
    onError (error: Error) {
      store.setRequestLoading(false)
      store.setCredentials({ token: '' })
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener las ordenes de trabajo',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    },
    retry: 3,
    refetchOnWindowFocus: false
  })

  const { data: workOrdersData } = useQuery({
    queryKey: ['getCustomerWorkOrdersConfigurationFn'],
    queryFn: () => getCustomerWorkOrdersConfigurationFn(),
    onSuccess () {
      store.setRequestLoading(false)
    },
    onError (error: Error) {
      store.setRequestLoading(false)
      store.setCredentials({ token: '' })
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener las ordenes de trabajo',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    },
    retry: 3,
    refetchOnWindowFocus: false
  })

  const { mutate: createCustomerWorkOrder, isLoading } = useMutation({
    mutationFn: (workOrder: WorkOrder) => createCustomerWorkOrderFn(
      {
        patient: workOrder.patient,
        description: workOrder.description,
        estimatedDeliveryDate: workOrder.estimatedDeliveryDate,
        printFile: workOrder.printFile,
        workOrderItems: workOrder.workOrderItems
      }
    ),
    onSuccess: () => {
      onSuccessWorkOrderCreation?.()
      refetch()
      addToastMessage({
        type: 'success',
        text: 'Orden de trabajo creada con éxito',
        id: Math.random().toString(36).substring(2, 9)
      })
    },
    onError: (error: Error) => {
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al crear orden de trabajo',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    }
  })

  const {
    pagesRange, lastPage, nextPage, prevPage
  } = usePagination({
    totalCount: parseInt(data?.total || '0', 10),
    pageSize: data?.pageSize || 1,
    siblingCount: 2,
    currentPage: data?.page || 1
  })

  return {
    customerWorkOrders: data?.data.map((workOrder) => ({
      id: workOrder.id,
      patientName: workOrder.patient.name,
      status: workOrder.state,
      description: workOrder.description || '',
      createdAt: workOrder.created_at ? format(new Date(workOrder.created_at), 'dd/MM/yyyy') : '',
      estimatedAt: workOrder.estimated_delivery_date ? format(new Date(workOrder.estimated_delivery_date), 'dd/MM/yyyy') : '',
      finishAt: workOrder.finish_at ? format(new Date(workOrder.finish_at), 'dd/MM/yyyy') : ''
    })),
    pagination: {
      currentPage: data?.page,
      lastPage,
      total: data?.total,
      pages: pagesRange,
      nextPage,
      prevPage
    },
    workOrdersData,
    createCustomerWorkOrder,
    isLoadingWorkOrderCreation: isLoading,
    isFetchingCustomerWorkOrders
  }
}
