import { Page } from '../components/pagination'

interface UsePaginationProps {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}: UsePaginationProps) => {
  const pageBufferSize = siblingCount

  const allPages = Math.ceil(totalCount / pageSize)

  const left = Math.max(1, (currentPage || 0) - pageBufferSize)

  const right = Math.min((currentPage || 0) + pageBufferSize, allPages)

  const pages: Page[] = Array.from({ length: right - left + 1 }, (_, index) => ({
    page: index + left,
    label: (index + left).toString(),
    active: index + left === currentPage
  }))

  if (currentPage !== allPages && right !== allPages) {
    pages.push(
      {
        page: null,
        label: '...',
        active: false
      },
      {
        page: allPages,
        label: allPages.toString(),
        active: currentPage === allPages
      }
    )
  }

  if (currentPage !== 1 && left !== 1) {
    pages.unshift(
      {
        page: 1,
        label: '1',
        active: currentPage === 1
      },
      {
        page: null,
        label: '...',
        active: false
      }
    )
  }

  return {
    pagesRange: pages,
    nextPage: currentPage !== allPages ? currentPage + 1 : null,
    prevPage: currentPage !== 1 ? currentPage - 1 : null,
    currentPage,
    lastPage: allPages,
    total: totalCount
  }
}
