export default function SkeletonItem () {
  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
      </div>
    </div>
  )
}
