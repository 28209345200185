function CydenIcon (props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={151}
      height={159}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 151 159"
      {...props}
    >
      <path
        d="M58.642 1.76C31.622 9.027 10.948 29.23 2.343 56.773.338 63.191 0 66.367 0 78.774c0 16.41 1.262 22.638 7.089 35 7.57 16.059 19.916 28.627 35.911 36.558 12.453 6.174 22.125 8.442 36 8.442 22.168 0 41.353-7.853 56.716-23.216 5.515-5.515 13.784-16.927 13.784-19.022 0-1.901-1.947-.091-6.336 5.889-8.174 11.138-20.456 19.865-35.164 24.988-7.223 2.516-9.311 2.787-21.5 2.787-12.16 0-14.294-.275-21.5-2.771-18.756-6.496-33.862-19.424-41.958-35.911-6.047-12.313-7.918-19.932-7.918-32.244 0-12.368 1.871-19.932 8.034-32.478C31.241 30.34 46.613 17.206 65 11.041c11.74-3.935 31.26-3.935 43 0 15.064 5.05 28.956 15.417 37 27.613 3.84 5.822 5.5 7.345 5.5 5.045 0-.59-2.087-4.304-4.639-8.25-9.296-14.38-24.365-26.098-41.167-32.012C96.722.631 94.754.352 81 .078 68.539-.17 64.754.115 58.642 1.76Zm-2.661 35.408c-3.956 1.723-8.926 7.463-10.625 12.27-2.027 5.735-1.682 35.267.547 46.836 4.075 21.149 12.672 29.984 20.757 21.329 3.297-3.53 12.235-21.144 11.638-22.935-.21-.629-2.727 2.43-5.594 6.795-2.867 4.367-6.625 9.385-8.352 11.151-2.885 2.951-3.279 3.084-4.872 1.642-4.404-3.986-7.437-25.178-6.806-47.562.494-17.563 2.793-22.067 12.026-23.565 4.671-.758 8.423 2.561 14.182 12.546 4.972 8.62 8.941 12.572 13.666 13.61 3.477.763 5.268-.1 2.345-1.132-4.299-1.517-8.235-6.712-11.514-15.192-2.304-5.96-4.604-9.952-7.436-12.908-4.007-4.182-4.246-4.278-10.522-4.24-3.531.022-7.779.631-9.44 1.355Zm39.826 1.683c-4.002 1.981-12.983 10.58-12.038 11.526.248.247 2.797-1.129 5.665-3.058 9.922-6.677 16.216-7.176 19.515-1.545 1.87 3.191 2.08 5.47 2.385 25.824.326 21.778-.793 37.362-2.975 41.439-.988 1.847-1.079 1.849-3.07.07-1.129-1.008-5.793-6.828-10.366-12.932-8.666-11.57-11.805-14.447-15.638-14.333L77 85.91l2.241 1.082c2.487 1.2 5.673 5.984 12.649 18.992 5.773 10.765 8.725 14.344 12.999 15.754 9.965 3.289 14.322-6.11 15.28-32.963.615-17.245-.741-35.032-3.219-42.236-3.212-9.34-11.624-12.399-21.143-7.687Z"
        fill="#fff"
      />
    </svg>
  )
}

export default CydenIcon
