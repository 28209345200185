import { useSearchParams } from 'react-router-dom'
import Table, { ColumnsType } from '../components/table'
import Pagination from '../components/pagination'
import { useCustomerBalance } from '../hooks/useCustomerBalance'
import { format } from 'date-fns'
import React, { useRef, useState } from 'react'
import Input from '../components/input'
import Button from '../components/button'
import { useAuth } from '../hooks/useAuth'

const ADMIN_URL = import.meta.env.VITE_ADMIN_URL ?? 'http://localhost'

interface DataType {
  key: string;
  balanceTypeId: number;
  description: string;
  amount: number;
  date: string;
  model: string;
  modelId: number;
}

export default function Balance () {
  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useAuth()
  const [dates, setDates] = useState<{ from: string; to: string }>({
    from: '',
    to: ''
  })
  const page = searchParams.get('page') || '1'
  const { customerBalance, pagination, isLoading } = useCustomerBalance({
    page,
    from: dates.from,
    to: dates.to
  })

  const dataSource = customerBalance.map((balance) => ({
    key: balance.id.toString(),
    balanceTypeId: balance.balance_type_id,
    description: balance.description,
    amount: balance.amount,
    date: balance.date,
    model: balance.model,
    modelId: balance.model_id
  }))

  const columns: ColumnsType<DataType> = [
    {
      title: 'Tipo',
      dataIndex: 'balance_type_id',
      key: 'balance_type_id',
      render: (_, { balanceTypeId }) => (balanceTypeId === 1 ? 'Debe' : 'Haber')
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, { amount }) =>
        new Intl.NumberFormat('es-AR', {
          style: 'currency',
          currency: 'ARS'
        }).format(amount)
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => format(new Date(text), 'dd/MM/yyyy')
    }
  ]

  const form = useRef<HTMLFormElement>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const values = e.target as unknown as Record<string, { value: string }>
    const from = values.from.value.toString()
    const to = values.to.value.toString()
    if (from && to) {
      setDates({ from, to })
    }
  }

  const resetForm = () => {
    form.current?.reset()
    setDates({ from: '', to: '' })
    setSearchParams(new URLSearchParams({ page: '1' }))
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">
            Balance de cuenta
          </h1>
          <div className="flex justify-between flex-col md:flex-row">
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">
              Muestra el detalle del balance de tu cuenta
            </p>
            <div className="flex flex-col gap-4 items-end">
              <form
                onSubmit={handleSubmit}
                className="w-100 flex items-center gap-2 flex-col md:flex-row"
                ref={form}
              >
                <Input type="date" id="from" required />
                <Input type="date" id="to" required />
                <div className="relative">
                  <Button type="submit" className="text-xs">Filtrar</Button>
                  <Button
                    onClick={resetForm}
                    fill="text"
                    className="text-xs disabled:text-primary hover:text-white hover:bg-transparent"
                    disabled={dates.from === '' && dates.to === ''}
                  >
                    Borrar
                  </Button>

                </div>
              </form>

              {dates.from && dates.to && (
                <a href={`${ADMIN_URL}/api/clientes/${user?.id}/downloadBalance?from=${dates.from}&to=${dates.to}`} download>Descargar PDF</a>
              )}
            </div>

          </div>
        </div>
      </div>
      <Table dataSource={dataSource} columns={columns} isFetching={isLoading} />
      <Pagination
        pages={pagination.pages || []}
        nextPage={pagination.nextPage}
        prevPage={pagination.prevPage}
      />
    </div>
  )
}
