import api from '..'
import { uploadFiles } from '../file'
import { uploadImages } from '../images'
import { WorkOrderConfigurationResponse, WorkOrdersResponse } from './types'
import * as Sentry from '@sentry/react'

interface WorkOrderItemRequest {
  work_order_type: string
  color?: string
  has_implant?: boolean
  implant_type?: string
  teeth?: number[]
  images?: string[]
  situation_model?: string
}

interface WorkOrderRequest {
  patient_id: number
  description: string
  estimated_delivery_date: string
  print_link: string[]
  order_items: WorkOrderItemRequest[]
}

export type WorkOrderItem = {
  type: string,
  color?: string,
  hasImplant: boolean,
  implantType?: string,
  teeth: number[],
  images?: FileList | null,
  situationModel?: string,
};

export interface WorkOrder {
  patient: number
  description: string
  printFile?: FileList | null
  estimatedDeliveryDate: string
  workOrderItems: WorkOrderItem[]
}

export const getCustomerWorkOrdersFn = async (page: string) => {
  const response = await api.get<WorkOrdersResponse>(`auth/customer/orders?page=${page}`)
  return response.data
}

export const createCustomerWorkOrderFn = async (
  {
    description,
    patient,
    printFile,
    estimatedDeliveryDate,
    workOrderItems
  }: WorkOrder
) => {
  const printFileUrls = await uploadFiles(printFile)

  const newWorkOrderItemsPromises = workOrderItems.map(async (workOrderItem) => {
    const imagesUrls = await uploadImages(workOrderItem.images)
    const {
      type, implantType, situationModel, hasImplant, ...rest
    } = workOrderItem
    return {
      ...rest,
      images: imagesUrls,
      work_order_type: type,
      implant_type: implantType,
      situation_model: situationModel,
      has_implant: hasImplant
    }
  })

  const newWorkOrderItems = await Promise.all(newWorkOrderItemsPromises)

  const orderData = {
    patient_id: patient,
    description,
    estimated_delivery_date: estimatedDeliveryDate,
    print_link: printFileUrls,
    order_items: newWorkOrderItems
  }

  Sentry.setExtras({ orderData, orderItems: orderData.order_items })
  Sentry.setExtra('stringifyData', JSON.stringify(orderData))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await api.post<any, any, WorkOrderRequest>('auth/customer/order', orderData)

  return response.data
}

export const getCustomerWorkOrdersConfigurationFn = async () => {
  const response = await api.get<WorkOrderConfigurationResponse>('auth/customer/work-order-configurations')
  return response.data
}

export const getCustomerWorkOrdersSummaryFn = async (page: string) => {
  const response = await api.get<WorkOrdersResponse>(`auth/customer/orders/in-progress?page=${page}&pageSize=30`)
  return response.data
}
