import ExclamationCircleIcon from './icons/ExclamationCircleIcon'

interface ErrorMessageProps {
  id: string;
  error: string;
  labelAlign: 'left' | 'right' | 'center';
  icon?: boolean;
}

function ErrorMessage ({
  error, id, labelAlign = 'left', icon = false
}: ErrorMessageProps) {
  return (
    <div className={`text-${labelAlign} my-2 text-sm text-red-600 flex justify-center gap-2`} id={`${id}-error`}>
      <p>{error}</p>
      {icon && error && <ExclamationCircleIcon className="h-5 w-5" />}
    </div>
  )
}

export default ErrorMessage
