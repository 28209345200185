import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Toast from '../components/toast'
import useStore from '../store'

function MainLayout () {
  const location = useLocation()
  const navigate = useNavigate()
  const userToken = useStore((state) => state.userCredentials.token)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const from = ((location.state as any)?.from.pathname as string) || '/dashboard'

  useEffect(() => {
    if (userToken) {
      navigate(from)
    }
  }, [userToken, navigate, from])

  return userToken
    ? <>LOADING</>
    : (
      <>
        <Toast />
        <Outlet />
      </>
      )
}

export default MainLayout
