import React from 'react'
import ExclamationCircleIcon from './icons/ExclamationCircleIcon'
import { omitObjectKey } from '../utils/omitObjectKey'

type InputProps = React.DetailedHTMLProps<
React.InputHTMLAttributes<HTMLInputElement>,
HTMLInputElement
>
&
{
  label?: string
  labelAlign?: 'left' | 'right' | 'center'
  error?: string
  innerRef?: React.Ref<HTMLInputElement>
};

function Input ({
  label,
  error,
  labelAlign = 'left',
  innerRef,
  ...props
}: InputProps) {
  return (
    <div>
      <label htmlFor={props.id} className={`text-${labelAlign} block text-sm font-semibold text-gray-700 dark:text-gray-200`}>
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          {...omitObjectKey(props, 'innerRef')}
          ref={innerRef}
          className={`block p-2 w-full rounded-md border ${!!error ? 'border-red-300' : 'border-gray-300'} pr-10 ${!!error ? 'text-red-900 focus:border-red-500' : ''} focus:outline-none sm:text-sm ${props.className}`}
        />
        {!!error && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5" />
        </div>
        )}
      </div>
      {!!error && (
      <p className={`text-${labelAlign} mt-2 text-sm text-red-600`} id={`${props.id}-error`}>
        {error}
      </p>
      )}
    </div>
  )
}

export default Input
