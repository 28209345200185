import { StateCreator } from 'zustand'
import { CustomerPatient } from '../../api/patients/types'

export type Store = {
  customerPatients: CustomerPatient[] | [];
};

export type Actions = {
  addPatient: (patient: CustomerPatient) => void;
  setPatients: (patients: CustomerPatient[]) => void
};

export type CustomerPatientsSlice = Store & Actions;

export const createCustomerPatientsSlice: StateCreator<
CustomerPatientsSlice,
[],
[],
CustomerPatientsSlice
> = (set) => ({
  customerPatients: [],
  addPatient:
  (patient) => set(
    (state) => ({
      ...state,
      customerPatients: [
        patient,
        ...state.customerPatients.slice(0, -1)
      ]
    })
  ),
  setPatients: (patients) => set((state) => ({ ...state, customerPatients: patients }))
})
