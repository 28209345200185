import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { AuthSlice, createAuthSlice } from './slices/AuthSlice'
import { createCustomerPatientsSlice, CustomerPatientsSlice } from './slices/CustomerPatientsSlice'
import { createToastSlice, ToastSlice } from './slices/ToastSlice'

export type BoundStoreType = AuthSlice & ToastSlice & CustomerPatientsSlice;

const useStore = create<BoundStoreType>()(devtools(persist((...set) => ({
  ...createAuthSlice(...set),
  ...createToastSlice(...set),
  ...createCustomerPatientsSlice(...set)
}), {
  name: 'store',
  partialize: (state) => ({ userCredentials: state.userCredentials })
})))

export default useStore
