import * as yup from 'yup'

yup.setLocale({
  mixed: {
    default: 'Campo no válido',
    notType: 'Campo no cumple con el tipo de dato',
    required: 'Campo requerido'
  },
  number: {
    positive: 'Debe ser mayor que 0',
    min: 'Debe tener al menos 10 dígitos incluido el cero inicial',
    max: 'Excede el máximo de dígitos permitidos'
  },
  string: {
    email: 'Correo electrónico no válido',
    min: 'Debe tener al menos dígitos incluido el cero',
    max: 'Excede el máximo de dígitos permitidos',
    matches: 'Solo se permiten números'
  }
})

export const updateProfileSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  password: yup.string(),
  passwordConfirm: yup.string().when('password', {
    is: (password: string) => !!password,
    then: (schema) => schema.required('Campo requerido') && schema.oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
    otherwise: (schema) => schema.notRequired()
  })
}).required()
