import axios, { AxiosError, AxiosResponse } from 'axios'
import useStore from '../store'
import * as Sentry from '@sentry/react'

const { token } = useStore.getState().userCredentials
const BASE_URL = import.meta.env.VITE_BASE_URL ?? 'http://localhost:3080'
const VITE_ADMIN_URL = import.meta.env.VITE_ADMIN_URL ?? 'http://localhost:8000'

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : ''
  }
})

export const laravelApi = axios.create({
  baseURL: VITE_ADMIN_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : ''
  }
})

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    Sentry.withScope(scope => {
      scope.setExtra('error', error?.response?.data)
      Sentry.captureException(error)
    })

    return Promise.reject(error)
  }
)

export default api
