import { Navigate, RouteObject } from 'react-router-dom'
import AuthLayout from '../layout/authLayout'
import MainLayout from '../layout/mainLayout'
import Dashboard from '../pages/Dashboard'
import Login from '../pages/Login'
import NotFound from '../pages/NotFound'
import Patients from '../pages/Patients'
import UserProfile from '../pages/UserProfile'
import WorkOrders from '../pages/WorkOrders'
import Balance from '../pages/Balance'

const publicRoutes: RouteObject = {
  path: '*',
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/login" />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]
}

const privateRoutes: RouteObject = {
  path: '*',
  element: <AuthLayout />,
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      path: 'perfil',
      element: <UserProfile />
    },
    {
      path: 'pacientes',
      element: <Patients />
    },
    {
      path: 'trabajos',
      element: <WorkOrders />
    },
    {
      path: 'balance',
      element: <Balance />
    }
  ]
}

const routes = [publicRoutes, privateRoutes]

export default routes
