import { useQuery } from '@tanstack/react-query'
import useStore from '../store'
import { getCustomerDashboardStatsFn } from '../api/dashboard'

export const useCustomerDashboard = () => {
  const addToastMessage = useStore((state) => state.addMessage)
  const store = useStore()

  const { data } = useQuery({
    queryKey: ['customerDashboardStats'],
    queryFn: getCustomerDashboardStatsFn,
    onSuccess () {
      store.setRequestLoading(false)
    },
    onError (error: Error) {
      store.setRequestLoading(false)
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener las ordenes de trabajo',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    },
    retry: 3,
    refetchOnWindowFocus: false
  })

  return {
    customerDashboardStats: data
  }
}
