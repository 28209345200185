import { BanknotesIcon, EnvelopeOpenIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import StatCard from '../components/stat_card'
import { useCustomerDashboard } from '../hooks/useCustomerDashboard'
import { StatComponentValue } from '../components/types'
import { CustomerWorkOrdersSummaryTable } from '../components/global/customer-work-orders-summary-table'

const STATS_COMPONENT_VALUES: Record<string, StatComponentValue> = {
  totalOrders: {
    name: 'Ordenes totales',
    icon: ClipboardDocumentListIcon
  },
  openOrders: {
    name: 'Ordenes abiertas',
    icon: EnvelopeOpenIcon
  },
  balance: {
    name: 'Balance',
    icon: BanknotesIcon
  }
}

export default function Dashboard () {
  const { customerDashboardStats } = useCustomerDashboard()

  const stats = Object.entries(customerDashboardStats || []).map(([key, value], idx) => ({
    id: idx,
    name: STATS_COMPONENT_VALUES[key].name,
    stat: value,
    change: null,
    changeType: null,
    icon: STATS_COMPONENT_VALUES[key].icon
  }))

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <StatCard
            key={item.id}
            change={item.change}
            changeType={item.changeType}
            icon={item.icon as StatComponentValue['icon']}
            name={item.name}
            stat={item.stat}
          />
        ))}
      </dl>
      <CustomerWorkOrdersSummaryTable />
    </div>
  )
}
