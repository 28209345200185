function ExclamationCircleIcon (props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={200}
      width={200}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 506.4 506.4"
      xmlSpace="preserve"
      {...props}
    >
      <circle
        style={{
          fill: '#df5c4e'
        }}
        cx={253.2}
        cy={253.2}
        r={249.2}
      />
      <path
        style={{
          fill: '#f4efef'
        }}
        d="M253.2 332.4c-10.8 0-20-8.8-20-19.6v-174c0-10.8 9.2-19.6 20-19.6s20 8.8 20 19.6v174c0 10.8-9.2 19.6-20 19.6zM253.2 395.6c-5.2 0-10.4-2-14-5.6s-5.6-8.8-5.6-14 2-10.4 5.6-14 8.8-6 14-6 10.4 2 14 6c3.6 3.6 6 8.8 6 14s-2 10.4-6 14c-3.6 3.6-8.8 5.6-14 5.6z"
      />
    </svg>
  )
}

export default ExclamationCircleIcon
