import React from 'react'
import { InboxIcon } from '@heroicons/react/24/outline'
import Skeleton from './skeletons/item'

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ColumnsType<T> = Array<{
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: string, record: T) => React.ReactNode;
}>;

type DataSourceType = Record<string, any> & {
  key: string;
};

interface TableProps<T> {
  dataSource: T[];
  columns: ColumnsType<T>;
  bordered?: boolean;
  isFetching?: boolean;
}

const skeletons = new Array(6)
  .fill({ component: <Skeleton />, key: 0 })
  .map((skeleton, index) => ({ ...skeleton, key: index }))

function Table<T extends DataSourceType = any> ({
  dataSource: data,
  columns,
  bordered = false,
  isFetching = false
}: TableProps<T>) {
  return (
    <div className="mt-8 flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className={`min-w-full divide-y divide-gray-300 ${bordered ? 'overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg' : ''}`}>
            <thead>
              <tr>
                {
                    columns.length
                      ? columns.map((column) => (
                      <th
                        key={column.key}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-semibold text-gray-900 dark:text-gray-100 tracking-wider"
                      >
                        {column.title}
                      </th>
                      ))
                      : skeletons.map((skeleton) => skeleton)
                  }
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white dark:bg-inherit">
              {
                    isFetching
                      ? skeletons.map(({ component: skeleton, key }) => (
                      <tr key={key}>
                        {

                            columns.map((column) => (
                              <td key={column.key} className="px-6 py-4 text-sm text-gray-500">
                                {skeleton}
                              </td>
                            ))
                        }
                      </tr>
                      ))
                      : data.length
                        ? data.map((item) => (
                      <tr key={item.key}>
                        {
                            columns.map((column) => (
                              <td key={column.key} className="px-6 py-4 text-sm text-gray-500 dark:text-gray-200">
                                {column.render
                                  ? column.render(typeof item[column.dataIndex] === 'string'
                                    ? item[column.dataIndex]
                                    : '', item)
                                  : item[column.dataIndex]}
                              </td>
                            ))
                        }
                      </tr>
                        ))
                        : (
                      <tr>
                        <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-200 text-center" colSpan={columns.length}>
                          <InboxIcon className="mx-auto h-12 w-12 text-gray-400" />
                          No hay datos para mostrar.
                        </td>
                      </tr>
                          )
                }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Table
