import { useQuery } from '@tanstack/react-query'
import { getCustomerWorkOrdersSummaryFn } from '../api/workOrders'
import useStore from '../store'
import { usePagination } from './usePagination'

export const useCustomerWorkOrdersSummary = (page = '1') => {
  const addToastMessage = useStore((state) => state.addMessage)

  const { data: workOrdersSummary, isFetching } = useQuery({
    queryKey: ['customerWorkOrdersSummary'],
    queryFn: () => getCustomerWorkOrdersSummaryFn(page),
    retry: 3,
    refetchOnWindowFocus: false,
    onError: (error: Error) => {
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener las ordenes de trabajo',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    }
  })

  const {
    pagesRange, lastPage, nextPage, prevPage, currentPage, total
  } = usePagination({
    totalCount: parseInt(workOrdersSummary?.total || '0', 10),
    pageSize: workOrdersSummary?.pageSize || 1,
    siblingCount: 2,
    currentPage: workOrdersSummary?.page || 1
  })

  return {
    workOrdersSummary,
    isFetching,
    pagination: {
      currentPage,
      lastPage,
      total,
      pages: pagesRange,
      nextPage,
      prevPage
    }
  }
}
