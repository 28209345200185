import { useQuery } from '@tanstack/react-query'
import useStore from '../store'
import { usePagination } from './usePagination'
import { getCustomerBalanceFn } from '../api/balance'

export const useCustomerBalance = ({ page = '1', from = '', to = '' }: { page: string, from?: string, to?: string }) => {
  const store = useStore()
  const addToastMessage = useStore((state) => state.addMessage)

  const { data, isLoading } = useQuery({
    queryKey: ['customerBalance', { page, from, to }],
    queryFn: () => getCustomerBalanceFn({ page, from, to }),
    onSuccess () {
      store.setRequestLoading(false)
    },
    onError (error: Error) {
      store.setRequestLoading(false)
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener el balance',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    },
    retry: 3,
    refetchOnWindowFocus: false
  })

  const {
    pagesRange, lastPage, nextPage, prevPage
  } = usePagination({
    totalCount: parseInt(data?.total || '0', 10),
    pageSize: data?.pageSize || 1,
    siblingCount: 2,
    currentPage: data?.page || 1
  })

  return {
    isLoading,
    customerBalance: data?.data || [],
    pagination: {
      currentPage: data?.page,
      lastPage,
      total: data?.total,
      pages: pagesRange,
      nextPage,
      prevPage
    }
  }
}
