import { useMutation } from '@tanstack/react-query'
import { updateCustomerProfileFn } from '../api/profile'
import useStore from '../store'
import { CustomerProifle } from '../api/profile/types'

export const useCustomerProfile = () => {
  const addToastMessage = useStore((state) => state.addMessage)
  const store = useStore((state) => state)

  const { mutate: updateCustomerProfile } = useMutation({
    mutationFn: (profile: CustomerProifle) => updateCustomerProfileFn(
      profile
    ),
    onSuccess: (data) => {
      store.setAuthUser(data)
      addToastMessage({
        type: 'success',
        text: 'Perfil actualizado correctamente',
        id: Math.random().toString(36).substring(2, 9)
      })
    },
    onError: (error: Error) => {
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al actualizar la información del perfil',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    }
  })

  return {
    updateCustomerProfile
  }
}
