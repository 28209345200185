import api from '.'
import { ILoginResponse, IUserResponse, LoginInput } from './types'

export const loginUserFn = async (user: LoginInput) => {
  const response = await api.post<ILoginResponse>('auth/customers/login', user)
  return response.data
}

export const getMeFn = async () => {
  const response = await api.get<IUserResponse>('auth/customer')
  return response.data
}
