import api from '..'

export const uploadFiles = async (files: FileList | null | undefined) => {
  if (!files) return Promise.resolve([])
  const response = []

  for (let i = 0; i < files.length; i++) {
    const formData = new FormData()
    formData.append('files', files[i])

    const uploaded = await api.post('auth/customer/upload/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    response.push(uploaded.data[0])
  }

  return response
}
