import { useMutation, useQuery } from '@tanstack/react-query'
import { shallow } from 'zustand/shallow'
import { createCustomerPatientFn, getCustomerPatientsFn, updateCustomerPatientFn } from '../api/patients'
import useStore from '../store'
import { usePagination } from './usePagination'
import { PatientForm, UpdateCustomerPatient } from '../api/patients/types'

export const useCustomerPatients = ({ page = '1', search = '' }: { page: string, search?: string }) => {
  const store = useStore()
  const customerPatients = useStore((state) => state.customerPatients, shallow)
  const addToastMessage = useStore((state) => state.addMessage)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['customerPatients', { page, search }],
    queryFn: () => getCustomerPatientsFn({ page, search }),
    onSuccess (response) {
      store.setPatients(response.data)
      store.setRequestLoading(false)
    },
    onError (error: Error) {
      store.setRequestLoading(false)
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener los pacientes',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    },
    retry: 3,
    refetchOnWindowFocus: false
  })

  const { mutate: createCustumerPatient } = useMutation({
    mutationFn: (patient: PatientForm) => createCustomerPatientFn(
      {
        name: patient.name,
        description: patient.description
      }
    ),
    onSuccess: () => {
      refetch({ queryKey: ['customerPatients', { page: '1', search: '' }] })
      addToastMessage({
        type: 'success',
        text: 'Paciente creado con éxito',
        id: Math.random().toString(36).substring(2, 9)
      })
    },
    onError: (error: Error & {response: {data: {error: string}}}) => {
      if (error.response.data.error) {
        addToastMessage({
          type: 'error',
          text: error.response.data.error,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al crear paciente',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    }
  })

  const { mutate: updateCustumerPatient } = useMutation({
    mutationFn: (patient: UpdateCustomerPatient) => updateCustomerPatientFn(
      {
        name: patient.name,
        description: patient.description,
        id: patient.id
      }
    ),
    onSuccess: () => {
      refetch({ queryKey: ['customerPatients', { page: '1', search: '' }] })
      addToastMessage({
        type: 'success',
        text: 'Paciente actualizado con éxito',
        id: Math.random().toString(36).substring(2, 9)
      })
    },
    onError: (error: Error) => {
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al actualizar el paciente',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    }
  })

  const {
    pagesRange, lastPage, nextPage, prevPage
  } = usePagination({
    totalCount: parseInt(data?.total || '0', 10),
    pageSize: data?.pageSize || 1,
    siblingCount: 2,
    currentPage: data?.page || 1
  })

  return {
    customerPatients,
    isLoading,
    pagination: {
      currentPage: data?.page,
      lastPage,
      total: data?.total,
      pages: pagesRange,
      nextPage,
      prevPage
    },
    createCustumerPatient,
    updateCustumerPatient
  }
}
