import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { Link, useLocation } from 'react-router-dom'

export interface Page {
  page: number | null;
  active: boolean;
  label: string;
}

interface PaginationProps {
  pages: Page[];
  nextPage: number | null;
  prevPage: number | null;
}

export default function Pagination ({ pages, nextPage, prevPage }: PaginationProps) {
  const location = useLocation()
  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 my-4 max-sm:p-0">
      <div className="-mt-px flex w-0 flex-1">
        <Link
          to={`${location.pathname}?page=${prevPage}`}
          className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 pr-1 text-sm font-medium text-gray-500 dark:text-gray-200 hover:border-secondary hover:text-primary ${prevPage ?? 'pointer-events-none'}`}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
          Anterior
        </Link>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map(({ page, active, label }, index) => (
          page === null
            ? (
            <dd
              key={`${page}${index}`}
              className={`inline-flex items-center border-t-2 pt-4 px-4 text-sm font-medium ${
                active ? 'text-primary border-primary' : 'border-transparent text-gray-500 dark:text-gray-200'
              }`}
            >
              {label}
            </dd>
              )
            : (
            <Link
              key={`${page}${index}`}
              to={`${location.pathname}?page=${page}`}
              className={`inline-flex items-center border-t-2 pt-4 px-4 text-sm font-medium ${
                active ? 'text-primary border-primary' : 'border-transparent text-gray-500 dark:text-gray-200 hover:border-secondary hover:text-primary'
              }`}
            >
              {label}
            </Link>
              )
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <Link
          to={`${location.pathname}?page=${nextPage}`}
          className={`inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 dark:text-gray-200 hover:border-secondary hover:text-primary ${nextPage ?? 'pointer-events-none'}`}
        >
          Siguiente
          <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
        </Link>
      </div>
    </nav>
  )
}
