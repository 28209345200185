import api from '..'
import { CustomerProifle } from './types'

export const updateCustomerProfileFn = async (
  data: CustomerProifle
) => {
  const datata: Partial<CustomerProifle> = {
    name: data.name,
    email: data.email,
    phone: data.phone
  }

  if (data.password !== '' && data.passwordConfirm !== '') {
    datata.password = data.password
    datata.passwordConfirm = data.passwordConfirm
  }

  const response = await api.patch('auth/customer/profile', datata)
  return response.data
}
