import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect, useReducer, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import Badge, { BadgeColorType } from '../components/badge'
import Button from '../components/button'
import Pagination from '../components/pagination'
import Table, { ColumnsType } from '../components/table'
import { useCustomerWorkOrders } from '../hooks/useCustomerWorkOrders'
import Modal from '../components/modal'
import Input from '../components/input'
import { omitObjectKey } from '../utils/omitObjectKey'
import SelectSearchCombobox from '../components/select_search_combobox'
import { useCustomerPatients } from '../hooks/useCustomerPatients'
import SelectMenu from '../components/select_menu'
import Toggle from '../components/toggle'
import CreatePatientModal from '../components/modals/create_or_update_patient_modal'
import { PatientForm } from '../api/patients/types'
import { createPatientSchema } from '../constants/schemas/createPatient'

import MultiSelectMenu from '../components/multi_select_menu'
import { WorkOrderItemContainer } from './types'
import ErrorMessage from '../components/error-message'
import { WorkOrderConfigurationResponse, WorkOrderType } from '../api/workOrders/types'
import { capitalize } from '../utils/capitalize'

const ADMIN_URL = import.meta.env.VITE_ADMIN_URL ?? 'http://localhost'

interface DataType {
  key: string;
  patient: string | null;
  description: string;
  status: string | null;
}

const STATUS_COLORS: Record<string, BadgeColorType> = {
  'en espera': 'light',
  'en proceso': 'warning',
  finalizado: 'success'
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'key',
    key: 'key',
    render: (text) => (
      <strong>
        #
        {text.padStart(6, '0')}
      </strong>
    )
  },
  {
    title: 'Paciente',
    dataIndex: 'patient',
    key: 'patient',
    render: (text) => <a href="#1">{text}</a>
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    render: (text) => {
      const textArray = text.split('\n').map((string) => string.split('_:'))
      let textString = ''

      if (text.includes('_:')) {
        for (let i = 0; i < textArray.length; i += 1) {
          textString += `<div><strong>${textArray[i][0]}:</strong> ${textArray[
            i
          ][1]?.trim()}</div>`
        }

        return <div dangerouslySetInnerHTML={{ __html: textString }} />
      }
      return <div className="whitespace-pre-line">{text}</div>
    }
  },
  {
    title: 'Creación',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (text) => text
  },
  {
    title: 'Entrega estimada',
    key: 'estimatedAt',
    dataIndex: 'estimatedAt',
    render: (text) => text
  },
  {
    title: 'Finalización',
    key: 'finishAt',
    dataIndex: 'finishAt',
    render: (text) => text
  },
  {
    title: 'Estado',
    key: 'status',
    dataIndex: 'status',
    render: (text, order) => (text.toLowerCase() === 'finalizado'
      ? (
      <a
        href={`${ADMIN_URL}/api/trabajos/${order.key}/pdf`}
        download
        title="Descargar"
        className="group relative"
      >
        <Badge
          className="transition-all"
          label={text.toUpperCase()}
          color={STATUS_COLORS[text]}
        />
        <span className="absolute left-0 opacity-0 w-full text-center uppercase bg-primary text-white px-2.5 py-0.5 text-xs font-medium rounded-full transition-all group-hover:opacity-100 group-hover:scale-110">
          Descargar
        </span>
      </a>
        )
      : (
      <Badge label={text.toUpperCase()} color={STATUS_COLORS[text]} />
        ))
  }
]

yup.setLocale({
  mixed: {
    default: 'Campo no válido',
    notType: 'Campo no cumple con el tipo de dato',
    required: 'Campo requerido'
  },
  number: {
    positive: 'Debe ser mayor que 0',
    min: 'Debe tener al menos 10 dígitos incluido el cero inicial',
    max: 'Excede el máximo de dígitos permitidos'
  },
  string: {
    email: 'Correo electrónico no válido',
    min: 'Debe tener al menos dígitos incluido el cero',
    max: 'Excede el máximo de dígitos permitidos',
    matches: 'Solo se permiten números'
  }
})

const schema = yup.object({
  patient: yup.string().required(),
  description: yup.string().required(),
  printLink: yup
    .mixed<FileList>()
    .test('requiredFile', 'El campo es requerido', (value) => !!value?.length),
  estimatedDeliveryDate: yup.string().required()
})

const workOrderContainerSchema = yup.object({
  workOrderType: yup
    .object({
      id: yup.number().required(),
      value: yup.string().required()
    })
    .required(),
  teeth: yup
    .array()
    .of<{ id: number; value: string }>(
      yup.object({
        id: yup.number().required(),
        value: yup.string().required()
      })
    )
    .when(['workOrderType'], {
      is: (workOrderType: WorkOrderType) => workOrderType.showTeethOptions,
      then: (yupSchema) => yupSchema.required(),
      otherwise: (yupSchema) => yupSchema.notRequired()
    }),
  color: yup.string().when(['workOrderType'], {
    is: (workOrderType: WorkOrderType) => workOrderType.showColorInput,
    then: (yupSchema) => yupSchema.required(),
    otherwise: (yupSchema) => yupSchema.notRequired()
  }),
  hasImplant: yup.boolean().required(),
  implantType: yup.string().when(['hasImplant', 'workOrderType'], {
    is: (hasImplant: boolean, workOrderType: WorkOrderType) => hasImplant || workOrderType.showImplantBrandAndConnection,
    then: (yupSchema) => yupSchema.required(),
    otherwise: (yupSchema) => yupSchema.notRequired()
  }),
  faceAndColorPhoto: yup.mixed<FileList>().when(['workOrderType'], {
    is: (workOrderType: WorkOrderType) => workOrderType.optionalPhotos ? false : (workOrderType.showFacePhoto || workOrderType.showDifferentPhotos),
    then: (yupSchema) => yupSchema.test(
      'requiredFile',
      'El campo es requerido',
      (value) => !!value?.length
    ),
    otherwise: (yupSchema) => yupSchema.notRequired()
  }),
  situationModel: yup.string().when(['workOrderType'], {
    is: (workOrderType: string) => workOrderType === 'Híbrida', // pendiente ???
    then: (yupSchema) => yupSchema.required(),
    otherwise: (yupSchema) => yupSchema.notRequired()
  })
})

interface WorkOrderContainerState {
  openContainer: boolean;
  workOrderItems: WorkOrderItemContainer[];
  error: string;
  workOrdersData: WorkOrderConfigurationResponse
}

type WorkOrderContainerAction =
  | {
    type: 'OPEN_CONTAINER';
  }
  | {
    type: 'CLOSE_CONTAINER';
  }
  | {
    type: 'ADD_WORK_ORDER_ITEM';
    payload: WorkOrderItemContainer;
  }
  | {
    type: 'REMOVE_WORK_ORDER_ITEM';
    payload: Pick<WorkOrderItemContainer, 'id'>;
  }
  | {
    type: 'UPDATE_WORK_ORDER_ITEM';
    payload: WorkOrderItemContainer;
  }
  | {
    type: 'SET_ERROR';
    payload: string;
  }
  | {
    type: 'CLEAR_ERROR';
  }
  | {
    type: 'CLEAR_WORK_ORDER_ITEMS';
  }
  | {
    type: 'SET_WORK_ORDER_CONFIG',
    payload: WorkOrderConfigurationResponse
  };

const workOrderContainerReducer = (
  state: WorkOrderContainerState,
  action: WorkOrderContainerAction
) => {
  switch (action.type) {
    case 'OPEN_CONTAINER':
      return {
        ...state,
        openContainer: true
      }
    case 'CLOSE_CONTAINER':
      return {
        ...state,
        openContainer: false
      }
    case 'ADD_WORK_ORDER_ITEM':
      return {
        ...state,
        workOrderItems: [...state.workOrderItems, action.payload]
      }
    case 'REMOVE_WORK_ORDER_ITEM':
      return {
        ...state,
        workOrderItems: state.workOrderItems.filter(
          (workOrderItem) => workOrderItem.id !== action.payload.id
        )
      }
    case 'UPDATE_WORK_ORDER_ITEM':
      return {
        ...state,
        workOrderItems: state.workOrderItems.map((workOrderItem) => {
          if (workOrderItem.id === action.payload.id) {
            return action.payload
          }
          return workOrderItem
        })
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      }
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: ''
      }
    case 'CLEAR_WORK_ORDER_ITEMS':
      return {
        ...state,
        workOrderItems: []
      }
    case 'SET_WORK_ORDER_CONFIG':
    {
      return {
        ...state,
        workOrdersData: action.payload
      }
    }
    default:
      return state
  }
}

const initialWorkOrderContainerState: WorkOrderContainerState = {
  openContainer: false,
  workOrderItems: [],
  error: '',
  workOrdersData: {
    colorOptions: [],
    teethOptions: [],
    workOrderTypeOptions: []
  }
}

export type WorkOrderFormData = yup.InferType<typeof schema>;
export type WorkOrderContainerFormData = yup.InferType<
  typeof workOrderContainerSchema
>;

function WorkOrders () {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1'

  const search = searchParams.get('search') || ''

  const { customerPatients, createCustumerPatient } = useCustomerPatients({
    page: '1',
    search
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const form = useForm<WorkOrderFormData>({
    resolver: yupResolver(schema)
  })

  const workOrderContainerForm = useForm<WorkOrderContainerFormData>({
    resolver: yupResolver(workOrderContainerSchema),
    defaultValues: { hasImplant: false }
  })

  const [workOrderContainerState, workOrderContainerDispatch] = useReducer(
    workOrderContainerReducer,
    initialWorkOrderContainerState
  )

  const {
    customerWorkOrders,
    workOrdersData,
    pagination,
    createCustomerWorkOrder,
    isLoadingWorkOrderCreation,
    isFetchingCustomerWorkOrders
  } = useCustomerWorkOrders({
    page,
    onSuccessWorkOrderCreation: () => {
      setIsModalVisible(false)
      setSearchParams({ page })
      form.reset()
      workOrderContainerForm.reset()
      workOrderContainerDispatch({ type: 'CLEAR_WORK_ORDER_ITEMS' })
    }
  })

  useEffect(() => {
    if (workOrdersData?.colorOptions?.length) {
      workOrderContainerDispatch({
        type: 'SET_WORK_ORDER_CONFIG',
        payload: workOrdersData
      })
    }
  }, [workOrdersData])

  const dataSource = customerWorkOrders?.map((workOrder) => ({
    key: workOrder.id.toString(),
    patient: workOrder.patientName,
    description: workOrder.description,
    status: workOrder.status,
    createdAt: workOrder.createdAt,
    estimatedAt: workOrder.estimatedAt,
    finishAt: workOrder.finishAt
  })) || []

  const [selectedOption, setSelectedOption] = useState({
    id: customerPatients[0]?.id,
    value: customerPatients[0]?.name
  })

  const onSubmit = (data: WorkOrderFormData) => {
    if (workOrderContainerState.error) {
      workOrderContainerDispatch({ type: 'OPEN_CONTAINER' })
      return
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      description, patient, estimatedDeliveryDate, ...rest
    } = data

    const patientId = selectedOption.id

    const workOrderItems = workOrderContainerState.workOrderItems?.map(
      (workOrderItem) => ({
        type: workOrderItem.type,
        color: workOrderItem.color,
        hasImplant: workOrderItem.hasImplant,
        implantType: workOrderItem.implantType,
        images: workOrderItem.images,
        situationModel: workOrderItem.situationModel,
        teeth: workOrderItem.teeth.map((tooth) => tooth.id)
      })
    )

    createCustomerWorkOrder({
      description,
      patient: patientId,
      estimatedDeliveryDate,
      workOrderItems,
      printFile: rest.printLink
    })
  }

  useEffect(() => {
    if (!search) return
    setSearchParams({ search, page })
  }, [search, setSearchParams, page])

  useEffect(() => {
    setSelectedOption({
      id: customerPatients[0]?.id,
      value: customerPatients[0]?.name
    })
  }, [customerPatients])

  const [isPatientModalVisible, setIsPatientModalVisible] = useState(false)

  const patientForm = useForm<PatientForm>({
    resolver: yupResolver(createPatientSchema)
  })

  const onSubmitPatient = (data: PatientForm) => {
    createCustumerPatient({
      name: data.name,
      description: data.description
    })
    setIsPatientModalVisible(false)
    form.reset()
  }

  useEffect(() => {
    if (!workOrderContainerState.workOrderItems.length) {
      workOrderContainerDispatch({
        type: 'SET_ERROR',
        payload: 'Debe agregar al menos una orden de trabajo'
      })
      return
    }
    workOrderContainerDispatch({ type: 'CLEAR_ERROR' })
  }, [workOrderContainerState.workOrderItems])

  const onSaveWorkOrderItem = async (data: WorkOrderContainerFormData) => {
    const {
      teeth,
      workOrderType,
      color,
      faceAndColorPhoto,
      hasImplant,
      implantType,
      situationModel
    } = data

    const images = (faceAndColorPhoto?.length && faceAndColorPhoto) || null

    const id = Math.random().toString(36).substring(2, 9)

    workOrderContainerDispatch({
      type: 'ADD_WORK_ORDER_ITEM',
      payload: {
        id,
        teeth: teeth || [],
        color,
        images,
        hasImplant,
        implantType,
        situationModel,
        type: workOrderType.value
      }
    })

    workOrderContainerDispatch({ type: 'CLOSE_CONTAINER' })
    workOrderContainerForm.setValue('teeth', [])
    workOrderContainerForm.setValue('color', '')
    workOrderContainerForm.setValue('implantType', '')
    workOrderContainerForm.setValue('situationModel', '')
    workOrderContainerForm.setValue('workOrderType.id', -1)
    workOrderContainerForm.setValue('hasImplant', false)
  }

  const onRemoveWorkOrderItem = (id: string) => {
    workOrderContainerDispatch({
      type: 'REMOVE_WORK_ORDER_ITEM',
      payload: { id }
    })
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">
            Trabajos
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">
            Una lista de todos tus trabajos incluidos sus nombres, descripción,
            etc.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button
            label="Nuevo trabajo"
            onClick={() => setIsModalVisible(true)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        isFetching={isFetchingCustomerWorkOrders}
      />
      <Pagination
        pages={pagination.pages || []}
        nextPage={pagination.nextPage}
        prevPage={pagination.prevPage}
      />
      <Modal
        visible={isModalVisible}
        title="Agregar nuevo trabajo"
        confirmButtonProps={{
          form: 'work-order-form',
          type: 'submit',
          label: 'Guardar trabajo',
          isLoading: isLoadingWorkOrderCreation,
          disabled: isLoadingWorkOrderCreation
        }}
        onConfirm={() => ({})}
        onCancel={() => {
          setIsModalVisible(false)
          setSearchParams({ page })
          workOrderContainerDispatch({ type: 'CLOSE_CONTAINER' })
          workOrderContainerDispatch({ type: 'CLEAR_WORK_ORDER_ITEMS' })
          form.reset()
          workOrderContainerForm.reset()
        }}
        closeOnBlur={false}
      >
        <form
          id="work-order-form"
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 mt-4"
        >
          <SelectSearchCombobox
            query={search}
            placeholder='Buscar paciente... (ej. "Miguel")'
            label="Paciente"
            options={customerPatients.map((patient) => ({
              id: patient.id,
              value: patient.name
            }))}
            onChangeQuery={(query) => setSearchParams({ search: query })}
            onChangeOption={(option) => {
              setSelectedOption(option)
              form.setValue('patient', option.value, { shouldValidate: true })
            }}
            error={form.formState.errors.patient?.message}
            emptyStateChildren={(
              <div className="flex flex-col items-center justify-center">
                <Button
                  label="Agregar paciente"
                  onClick={() => setIsPatientModalVisible(true)}
                  className="text-[12px] px-1 py-1"
                />
              </div>
            )}
          />
          <Input
            label="Descripción"
            type="text"
            id="description"
            {...omitObjectKey(form.register('description'), 'ref')}
            innerRef={form.register('description').ref}
            error={form.formState.errors.description?.message}
          />
          <div className="grid grid-cols-2 gap-5">
            <Input
              label="Fecha de entrega estimada"
              className="h-[38px]"
              type="date"
              id="estimatedDeliveryDate"
              {...omitObjectKey(form.register('estimatedDeliveryDate'), 'ref')}
              innerRef={form.register('estimatedDeliveryDate').ref}
              error={form.formState.errors.estimatedDeliveryDate?.message}
            />
            <Input
              label="Archivo de impresión"
              type="file"
              multiple
              className="h-[38px]"
              id="printLink"
              {...omitObjectKey(form.register('printLink'), 'ref')}
              innerRef={form.register('printLink').ref}
              error={form.formState.errors.printLink?.message}
            />
          </div>
          <ul className="mt-3 grid grid-cols-1 gap-2">
            {workOrderContainerState.workOrderItems?.map((workOrder) => (
              <li
                key={workOrder.id}
                className="col-span-1 flex rounded-md shadow-sm"
              >
                <div className="flex flex-1 items-center justify-between truncate text-sm rounded-r-md border border-gray-200 bg-white">
                  <div className="px-4 py-2 font-medium">{workOrder.type}</div>

                  <div
                    className="h-6 w-px bg-gray-900/10 lg:hidden"
                    aria-hidden="true"
                  />

                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    {workOrder.teeth.map((tooth) => tooth.value).join(', ')}
                    <p className="text-gray-500">
                      {workOrder.teeth.length}
                      {' '}
                      {workOrder.teeth.length === 1 ? 'Diente' : 'Dientes'}
                    </p>
                  </div>

                  <div
                    className="h-6 w-px bg-gray-900/10 lg:hidden"
                    aria-hidden="true"
                  />

                  <div className="truncate px-4 py-2 text-sm">
                    {workOrder?.images?.length || 0}
                    {' '}
                    Fotos
                  </div>
                  <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => onRemoveWorkOrderItem(workOrder.id)}
                    >
                      <span className="sr-only">Open options</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Button
            fill="outline"
            className="w-full"
            onClick={() => workOrderContainerDispatch({ type: 'OPEN_CONTAINER' })}
          >
            + Agregar trabajo
          </Button>
          <ErrorMessage
            error={workOrderContainerState.error}
            id="workOrderContainer"
            labelAlign="center"
            icon
          />
          {workOrderContainerState.openContainer && (
            <div className="rounded-md shadow-sm border p-4 bg-gray-400 bg-opacity-10">
              <div className="grid grid-cols-2 gap-5 mb-4">
                <SelectMenu
                  label="Tipo de trabajo"
                  options={workOrderContainerState.workOrdersData.workOrderTypeOptions
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((workOrderType, index) => ({
                      ...workOrderType,
                      id: index,
                      value: capitalize(workOrderType.name)
                    }))}
                  onChangeOption={(option) => {
                    workOrderContainerForm.setValue('workOrderType', option)
                    workOrderContainerForm.clearErrors()
                  }}
                  error={
                    workOrderContainerForm.formState.errors.workOrderType?.value
                      ?.message
                  }
                />
                {workOrderContainerState.workOrdersData.workOrderTypeOptions[
                  workOrderContainerForm.watch('workOrderType.id')
                ]?.showTeethOptions && (
                  <MultiSelectMenu
                    label="Diente"
                    options={workOrderContainerState.workOrdersData.teethOptions
                      .map((tooth) => ({
                        id: tooth.numeration,
                        value: tooth.name
                      }))
                      .filter((tooth) => {
                        const workOrderTeeth = workOrderContainerState.workOrderItems?.flatMap(
                          (workOrder) => workOrder.teeth.map((t) => t.id)
                        )
                        return !workOrderTeeth?.includes(tooth.id)
                      })}
                    onChangeOption={(option) => {
                      workOrderContainerForm.setValue('teeth', option, {
                        shouldValidate: true
                      })
                    }}
                    error={
                      workOrderContainerForm.formState.errors.teeth?.message
                    }
                    renderOption={(option) => `${option.id} - ${option.value}`}
                  />
                )}
                {(workOrderContainerState.workOrdersData.workOrderTypeOptions[
                  workOrderContainerForm.watch('workOrderType.id')
                ]?.showFacePhoto || workOrderContainerState.workOrdersData.workOrderTypeOptions[
                  workOrderContainerForm.watch('workOrderType.id')
                ]?.showDifferentPhotos ||
                  workOrderContainerState.workOrdersData.workOrderTypeOptions[
                    workOrderContainerForm.watch('workOrderType.id')
                  ]?.optionalPhotos) && (
                  <Input
                    label={`${workOrderContainerState.workOrdersData.workOrderTypeOptions[
                      workOrderContainerForm.watch('workOrderType.id')
                    ]?.showFacePhoto && workOrderContainerState.workOrdersData.workOrderTypeOptions[
                      workOrderContainerForm.watch('workOrderType.id')
                    ]?.showDifferentPhotos
                    ? 'Fotos de cara y color'
                    : workOrderContainerState.workOrdersData.workOrderTypeOptions[
                      workOrderContainerForm.watch('workOrderType.id')
                    ]?.showFacePhoto && !workOrderContainerState.workOrdersData.workOrderTypeOptions[
                      workOrderContainerForm.watch('workOrderType.id')
                    ]?.showDifferentPhotos
                    ? 'Fotos de cara'
                    : 'Fotos de color'}`}
                    type="file"
                    accept='image/*'
                    multiple
                    className="bg-white h-[38px]"
                    {...omitObjectKey(
                      workOrderContainerForm.register('faceAndColorPhoto'),
                      'ref'
                    )}
                    innerRef={
                      workOrderContainerForm.register('faceAndColorPhoto').ref
                    }
                    error={
                      workOrderContainerForm.formState.errors.faceAndColorPhoto
                        ?.message
                    }
                  />
                )}
                {workOrderContainerForm.watch('workOrderType.value') ===
                  'Híbrida' && (
                  <Input
                    label="Modelo de situación"
                    type="text"
                    id="situationModel"
                    {...omitObjectKey(
                      workOrderContainerForm.register('situationModel'),
                      'ref'
                    )}
                    innerRef={
                      workOrderContainerForm.register('situationModel').ref
                    }
                    error={
                      workOrderContainerForm.formState.errors.situationModel
                        ?.message
                    }
                  />
                )}
                {workOrderContainerState.workOrdersData.workOrderTypeOptions[
                  workOrderContainerForm.watch('workOrderType.id')
                ]?.showColorInput && (
                  <SelectMenu
                    label="Color"
                    options={workOrderContainerState.workOrdersData.colorOptions
                      .sort((a, b) => (a < b ? -1 : 1))
                      .map((name, index) => ({ id: index, value: name }))}
                    onChangeOption={(option) => {
                      workOrderContainerForm.setValue('color', option.value, {
                        shouldValidate: true
                      })
                    }}
                    error={
                      workOrderContainerForm.formState.errors.color?.message
                    }
                  />
                )}
                {workOrderContainerState.workOrdersData.workOrderTypeOptions[
                  workOrderContainerForm.watch('workOrderType.id')
                ]?.showToggle && (
                  <Toggle
                    enabled={workOrderContainerForm.watch('hasImplant')}
                    onChange={(enabled) => {
                      workOrderContainerForm.setValue('hasImplant', enabled)
                    }}
                    label="¿Tiene implante?"
                  />
                )}
                {(workOrderContainerForm.watch('hasImplant') ||
                  workOrderContainerState.workOrdersData.workOrderTypeOptions[
                    workOrderContainerForm.watch('workOrderType.id')
                  ]?.showImplantBrandAndConnection) && (
                  <Input
                    label="Tipo implante, marca y conexión"
                    type="text"
                    id="implantType"
                    {...omitObjectKey(
                      workOrderContainerForm.register('implantType'),
                      'ref'
                    )}
                    innerRef={
                      workOrderContainerForm.register('implantType').ref
                    }
                    error={
                      workOrderContainerForm.formState.errors.implantType
                        ?.message
                    }
                  />
                )}
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <Button
                  label="Guardar trabajo"
                  fill="outline"
                  onClick={workOrderContainerForm.handleSubmit(
                    onSaveWorkOrderItem
                  )}
                >
                  Guardar trabajo
                </Button>
                <Button
                  fill="outline"
                  className="text-slate-900 bg-transparent border-none"
                  onClick={() => {
                    workOrderContainerDispatch({ type: 'CLOSE_CONTAINER' })
                    workOrderContainerForm.reset()
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          )}
        </form>
      </Modal>
      <CreatePatientModal
        isModalVisible={isPatientModalVisible}
        setIsModalVisible={setIsPatientModalVisible}
        form={patientForm}
        onSubmit={onSubmitPatient}
      />
    </div>
  )
}

export default WorkOrders
