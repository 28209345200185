import api from '..'
import { CustomerBalanceResponse } from './types'

export const getCustomerBalanceFn = async (
  {
    page = '1',
    from,
    to
  }: { page: string, from: string, to: string }
) => {
  const queryParams = new URLSearchParams({
    page,
    from,
    to
  })
  const response = await api.get<CustomerBalanceResponse>(`auth/customer/balance?${queryParams}`)
  return response.data
}
