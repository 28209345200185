import { StateCreator } from 'zustand'

export type ToastMessageType = 'success' | 'error' | 'warning';

interface ToastMessage {
  type: ToastMessageType
  text: string
  id: string
}

export interface ToastSlice {
  messages: ToastMessage[]
  addMessage: (messageInput: ToastMessage) => void
  removeMessage: (id: string) => void
}

export const createToastSlice: StateCreator<
ToastSlice,
[],
[],
ToastSlice
> = (set) => ({
  messages: [],
  addMessage: (messageInput) => set((state) => ({
    ...state,
    messages: [...state.messages, messageInput]
  })),
  removeMessage: (id) => set((state) => ({
    ...state,
    messages: state.messages.filter((message) => message.id !== id)
  }))
})
