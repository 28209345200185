import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import ExclamationCircleIcon from './icons/ExclamationCircleIcon'

type OptionItem = {
  id: number;
  value: string;
};
interface SelectMenuProps {
  onChangeOption: (option: OptionItem) => void;
  error: string | undefined;
  labelAlign?: 'left' | 'right' | 'center';
  label: string;
  options: Array<OptionItem>;
}

function classNames (...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function SelectMenu ({
  label,
  labelAlign = 'left',
  options,
  error,
  onChangeOption
}: SelectMenuProps) {
  const [selectedOption, setSelectedOption] = useState({ id: -1, value: 'Selecciona una opción' })

  return (
    <Listbox
      value={selectedOption}
      onChange={(value) => {
        onChangeOption(value)
        setSelectedOption(value)
      }}
      as="div"
    >
      {({ open }) => (
        <>
          <Listbox.Label className={`block text-sm text-${labelAlign} font-semibold leading-6 text-gray-900 h-[20px]`}>{label}</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className={`relative w-full cursor-default rounded-md bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ${!!error ? 'ring-red-500 text-red-500' : 'ring-gray-300'} focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 min-h-[36px]`}>
              <span className="block truncate">{selectedOption.value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                {!!error && (
                <ExclamationCircleIcon className="h-5 w-5" />
                )}
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) => classNames(
                      active ? 'bg-primary text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.value}
                        </span>

                        {selected
                          ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                            )
                          : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {!!error && (
            <p className="text-left mt-2 text-sm text-red-600">
              {error}
            </p>
            )}
          </div>
        </>
      )}
    </Listbox>
  )
}
