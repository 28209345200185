import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { getMeFn } from '../api/auth'
import Sidebar from '../components/sidebar'
import Toast from '../components/toast'
import useStore from '../store'
import * as Sentry from '@sentry/react'

export default function AuthLayout () {
  const store = useStore((state) => state)
  const userToken = useStore((state) => state.userCredentials.token)
  const addToastMessage = useStore((state) => state.addMessage)
  const navigate = useNavigate()

  const { refetch } = useQuery(['getMe'], getMeFn, {
    onSuccess (data) {
      store.setAuthUser(data)
      store.setRequestLoading(false)
      Sentry.setUser({
        id: data.id.toString(),
        username: data.name,
        email: data.email
      })
    },
    onError (error: Error) {
      store.setRequestLoading(false)
      store.setCredentials({ token: '' })
      if (error instanceof AxiosError && error.code === 'ERR_BAD_REQUEST') {
        addToastMessage({
          type: 'warning',
          text: 'Sesión expirada, por favor inicie sesión nuevamente',
          id: Math.random().toString(36).substring(2, 9)
        })
        return
      }
      if (error.message) {
        addToastMessage({
          type: 'error',
          text: error.message,
          id: Math.random().toString(36).substring(2, 9)
        })
      } else {
        addToastMessage({
          type: 'error',
          text: 'Error desconocido ocurrido al obtener los datos del usuario',
          id: Math.random().toString(36).substring(2, 9)
        })
      }
    },
    refetchOnWindowFocus: false,
    retry: false
  })

  useEffect(() => {
    if (!userToken) {
      navigate('/login', { replace: true })
      return
    }
    refetch()
  }, [userToken, refetch, navigate])

  return (
    !userToken
      ? <>LOADING</>
      : (
      <>
        <Toast />
        <Sidebar>
          <Outlet />
        </Sidebar>
      </>
        )
  )
}
