import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import Button, { ButtonProps } from './button'

interface ModalProps {
  visible: boolean;
  title: string;
  description?: string;
  children?: React.ReactNode;
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps;
  onConfirm: () => void;
  onCancel: () => void;
  closeOnBlur?: boolean;
}

export default function Modal ({
  visible,
  title,
  description,
  children,
  confirmButtonProps,
  cancelButtonProps,
  closeOnBlur = true,
  onConfirm,
  onCancel
}: ModalProps) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={closeOnBlur ? onCancel : () => ({})}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 text-green-600 dark:bg-green-800 dark:text-green-200">
                    <CheckIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {description && (
                      <p className="text-sm text-gray-500 dark:text-gray-200">
                        {description}
                      </p>
                      )}
                      {children}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <Button fill="outline" onClick={onCancel} {...cancelButtonProps}>
                    Cancelar
                  </Button>
                  <Button {...confirmButtonProps} onClick={onConfirm} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
