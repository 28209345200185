import api from '..'
import { CustomerPatient, CustomerPatientsResponse, UpdateCustomerPatient } from './types'

export const getCustomerPatientsFn = async (
  {
    page = '1',
    search
  }: { page: string, search?: string }
) => {
  const response = await api.get<CustomerPatientsResponse>(`auth/customer/patients?page=${page}${search ? `&search=${search}` : ''}`)
  return response.data
}

export const createCustomerPatientFn = async (
  {
    name,
    description
  }: Omit<CustomerPatient, 'id'>
) => {
  const response = await api.post('auth/customer/patient', {
    name,
    description
  })

  return response.data
}

export const updateCustomerPatientFn = async (
  {
    name,
    description,
    id
  }: UpdateCustomerPatient
) => {
  const response = await api.patch('auth/customer/patient', {
    name,
    description,
    id
  })
  return response.data
}
