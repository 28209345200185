import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import UpdateProfileModal from '../components/modals/update_profile'
import { useAuth } from '../hooks/useAuth'
import { CustomerProifle } from '../api/profile/types'
import { updateProfileSchema } from '../constants/schemas/updateProfile'
import { useCustomerProfile } from '../hooks/useCustomerProfile'
import Button from '../components/button'

export default function UserProfile () {
  const { user } = useAuth()
  const { updateCustomerProfile } = useCustomerProfile()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const form = useForm<CustomerProifle>({ resolver: yupResolver(updateProfileSchema) })

  const setModal = () => {
    form.setValue('name', user?.name ?? '')
    form.setValue('email', user?.email ?? '')
    form.setValue('phone', user?.phone ?? '')
    setIsModalVisible(true)
  }

  const onSubmit = (data: CustomerProifle) => {
    updateCustomerProfile(data)
    setIsModalVisible(false)
    form.reset()
  }

  return (
    <>
      <div className="overflow-hidden bg-white dark:bg-inherit shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">Información de usuario</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-200">Detalles personales</p>
          </div>

          <Button label="Actualizar información" onClick={setModal} />

        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-bold text-gray-500 dark:text-gray-200">Nombre completo</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-50 sm:col-span-2 sm:mt-0">{user?.name}</dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-bold text-gray-500 dark:text-gray-200">Correo electrónico</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-50 sm:col-span-2 sm:mt-0">{user?.email}</dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-bold text-gray-500 dark:text-gray-200">Teléfono</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-50 sm:col-span-2 sm:mt-0">{user?.phone}</dd>
            </div>
          </dl>
        </div>
      </div>
      <UpdateProfileModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        form={form}
        onSubmit={onSubmit}
      />
    </>
  )
}
