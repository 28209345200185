import { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import ExclamationCircleIcon from './icons/ExclamationCircleIcon'

type OptionItem = {
  id: number;
  value: string;
};

interface SelectSearchComboboxProps {
  labelAlign?: 'left' | 'right' | 'center';
  query: string;
  placeholder?: string;
  error: string | undefined;
  label: string;
  options: Array<OptionItem>;
  onChangeQuery: (query: string) => void;
  onChangeOption: (option: OptionItem) => void;
  emptyStateChildren?: React.ReactNode;
}

function classNames (...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function SelectSearchCombobox ({
  labelAlign = 'left',
  query,
  placeholder = 'Buscar...',
  error,
  label,
  options,
  onChangeQuery,
  onChangeOption,
  emptyStateChildren
}: SelectSearchComboboxProps) {
  const [selectedOption, setSelectedOption] = useState<OptionItem>({ id: 0, value: '' })

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={(option) => {
        onChangeOption(option)
        setSelectedOption(option)
      }}
    >
      <Combobox.Label className={`block text-sm leading-6 text-gray-900 text-${labelAlign} font-semibold`}>{label}</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          placeholder={placeholder}
          className={`w-full rounded-md ${!!error ?? 'border-0'} bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ${!!error ? 'ring-1 ring-inset ring-red-500' : 'ring-1 ring-inset ring-gray-300'} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6`}
          onChange={(event) => onChangeQuery(event.target.value)}
          displayValue={(person: OptionItem) => person.value}
          autoComplete="off"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {!!error && (
          <ExclamationCircleIcon className="h-5 w-5" />
          )}
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {
            options.length === 0 && query !== '' && (
              <>
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nada encontrado
                </div>
                {emptyStateChildren}
              </>
            )
          }
          {options.map((option) => (
            <Combobox.Option
              key={option.id}
              value={option}
              className={({ active }) => classNames(
                'relative cursor-default select-none py-2 pl-3 pr-9',
                active ? 'bg-primary text-white' : 'text-gray-900'
              )}
            >
              {({ active, selected }) => (
                <>
                  <span className={classNames('block truncate', (selected || option.id === selectedOption.id) ? 'font-semibold' : '')}>{option.value}</span>

                  {(selected || option.id === selectedOption.id) && (
                  <span
                    className={classNames(
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                      active ? 'text-white' : 'text-primary'
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
      {!!error && (
      <p className="text-left mt-2 text-sm text-red-600">
        {error}
      </p>
      )}
    </Combobox>
  )
}
